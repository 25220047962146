/*
 * @LastEditTime: 2022-08-05 15:02:48
 * @Description: 展示字段详情，包括虚拟字段
 * @FilePath: /dataview-next/src/global/actions/ShowFieldDetail.js
 * @Date: 2022-04-18 20:14:20
 * @Author: lisushuang
 * @LastEditors: lisushuang
 */
/* eslint-disable */
import { Col, Input, Message, Row } from "element-ui";
import Vue from "vue";
import objects from "../../api/objects";
import baseAction from "./baseAction";
import { Button } from 'element-ui';
import ValueForm from "../../views/objects/components/valueForm.vue"


export default class ShowFieldDetail extends baseAction {

  field = new Vue({ data: { detail: null } });

  constructor() {
    super()
  }

  handler() {
    console.log('ShowFieldDetail', this.props)
    let field_uuid = null;

    if (this.props) {
      if (this.props.uuid !== undefined) field_uuid = this.props.uuid
      else if (this.props.field_uuid !== undefined) field_uuid = this.props.field_uuid
      else field_uuid = this.props
    } 
    
    if(!field_uuid){
      this.showError('展示字段详情：非法参数！')
      return
    }

    this.getLoading()
    objects.getFieldDetail(field_uuid).then(res => {
      this.field.detail = res.data.data
      this.showPopDialog(
        this.showFieldSetting,
        '【字段】：' + this.field.detail.name + ' 【code】：' + this.field.detail.code,
        this.footer
      )
      this.loading.close()
    }).catch(() => {
      this.loading.close()
    })
  }

  /**
   * @description: 保存按钮
   */  
  footer = () => {
    let h = this.mountInstance.$createElement
    return (
      <div>
        <Button type="success" onClick={()=> this.saveFieldSetting()}> 保存{this.field.detail.type == 'append' ? '虚拟' :''}字段设置 </Button>
      </div>
    )
  }

  /**
   * @description: 保存字段信息
   */  
  saveFieldSetting = () => {
    this.getLoading('字段保存中...')
    objects.editField(this.field.detail.uuid,this.field.detail).then(res => {
      this.loading.close()
      if(res.data.code == 200){
        Message.success("保存字段信息成功")
      }else{
        Message.error(res.data.msg)
      }
    }).catch(() => {
      this.loading.close()
      this.showError('字段信息保存失败')
    })
  }

  /**
   * @description: 展示字段信息以及配置
   * @param {CleateElement} h 
   * @return {Jsx} 
   */
  showFieldSetting = ()  => {
    let h = this.mountInstance.$createElement
    return (
      <div>
        <Row style="margin-bottom:5px">
          <Col span={12} style="padding:5px">
            <Input value={this.field.detail.name} onInput={(value) => this.field.detail.name = value}  >
              <span slot="prepend">字段名称</span>
            </Input>
          </Col>

          <Col span={12} style="padding:5px">
            <Input value={this.field.detail.comment} onInput={(value) => this.field.detail.comment = value} >
              <span slot="prepend">字段备注</span>
            </Input>
          </Col>
        </Row>

        <Row style="margin-bottom:5px">
          <Col span={12} style="padding:5px">
            <Input value={this.field.detail.code} disabled >
              <span slot="prepend">字段code</span>
            </Input>
          </Col>

          <Col span={12} style="padding:5px">
            <Input value={this.field.detail.uuid} disabled >
              <span slot="prepend">字段uuid</span>
            </Input>
          </Col>
        </Row>

        <Row style="margin-bottom:5px">
          <Col span={12} style="padding:5px">
            <Input value={objects.getFieldTypeName(this.field.detail.type)} disabled >
              <span slot="prepend">字段类型</span>
            </Input>
          </Col>

          <Col span={12} style="padding:5px">
            <Input value={this.field.detail.default} disabled >
              <span slot="prepend">字段默认值</span>
            </Input>
          </Col>
        </Row>

        <Row style="margin-bottom:5px">
          <Col span={12} style="padding:5px">
            <Input value={this.field.detail.created_at} disabled >
              <span slot="prepend">创建时间</span>
            </Input>
          </Col>

          <Col span={12} style="padding:5px">
            <Input value={this.field.detail.updated_at} disabled>
              <span slot="prepend">修改时间</span>
            </Input>
          </Col>
        </Row>

        { this.field.detail.type == 'append' ?  
        <Row style="margin-bottom:5px" >
          <ValueForm
            formData={this.field.detail.extra}
            obuuid={this.field.detail.object_uuid}
            realTime
            onInput={(value) => this.field.detail.extra = value}
          />
        </Row> : ''}
      </div>
    )
  }
}
